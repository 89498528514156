
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import {PopulationStatistics} from "../../models/reports/populationstatistics";
import {formatFinancialAmounts} from '../../utils/format'

export default Vue.extend({
  name: "PopulationStatistics",
  data: () => ({
    populationData: [] as PopulationStatistics[],
    isLoading: true,
  }),
  computed: {
    selectedSurvey() {
      return this.$store.getters["agsreport/getYearSelected"];
    },
    headers() {
      return [
        {
          text: "N",
          value: "n",
        },
        {
          text: i18n.t("reports.agsreports.yearselected"),
          value: "surveyYear",
        },
        {
          text: `${i18n.t("basic.country")}`,
          value: "country",
        },
        {
          text: i18n.t("population.pagetitle"),
          value: "population",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t("reports.agsreports.hemophilia")}`,
          value: "hemophilia",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: "vWD",
          value: "vwd",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t("reports.agsreports.otherbleedingdisorders")}`,
          value: "otherBleedingDisorders",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t("reports.agsreports.datayear")}`,
          value: "dataYear",
        },
      ];
    },
  },
  methods: {
    getPopulationData(year: number) {
      this.isLoading = true;
      axios
          .get(`Report/WFH/PopulationStatistics?year=${year}`)
          .then((res) => {
            this.populationData = res.data;
            this.isLoading = false;
          })
          .catch((err) => console.log(err));
    },
    exportData() {
      axios
          .get(
              `Report/WFH/PopulationStatistics/export?year=${this.selectedSurvey.year}`,
              {responseType: "blob"}
          )
          .then((res) => {
            const fileName = `${i18n.t(
                "navbar.menu.reportsub.ags_reports"
            )} - ${i18n.t("reports.agsreports.populationstatics")}`;
            this.downloadExcel(fileName, res.data);
          })
          .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], {type: "aplication/xlsx"});
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.selectedSurvey.year) {
      this.getPopulationData(this.selectedSurvey.year);
    }
  },
  watch: {
    selectedSurvey() {
      this.getPopulationData(this.selectedSurvey.year);
    },
  },
});
